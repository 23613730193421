import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

export interface ITest {
  test: string;
}

@Injectable()

export class HttpService {

  constructor(private http: HttpClient, private oidc: OidcSecurityService) { }


  //apiUrl = 'https://localhost:44392/api/';

  apiUrl = 'https://test.savebelow.nl/api/';
  //apiUrl = '/api/';




  id_token: string;
  id_organisation: string = '';
  currentHeader: HttpHeaders;
  loadingProgress;
  percentageDone = 0;
  /**
   * Get Request
   * @param url complete API url aanroep
   * @param headers HttpHeaders object
   */
  get(url: string,headers ?: HttpHeaders): Observable<any> {
    if(!headers){
      headers= new HttpHeaders();
    }
    headers = headers.set('Authorization', 'Bearer ' + this.id_token);
    if (this.id_organisation !== '') {
      headers = headers.set('id_organisation', this.id_organisation);
    }
    this.currentHeader = headers;
    return this.http.get(url, { headers: headers });
  }

  getText(url: string): Observable<any> {
    let currentHeader = new HttpHeaders();
    currentHeader = currentHeader.set('Authorization', 'Bearer ' + this.id_token);
    if (this.id_organisation !== '') {
      currentHeader = currentHeader.set('id_organisation', this.id_organisation);
    }
    this.currentHeader = currentHeader;
    return this.http.get(url, { headers: currentHeader, responseType : 'text' });
  }

  /**
   * Post Request
   * @param url complete API url aanroep
   * @param body de body
   * @param headers HttpHeaders object
   */
  post(url: string, body: any, headers ?: HttpHeaders): Observable < any > {
    let currentHeader = new HttpHeaders();
    currentHeader = currentHeader.set('Authorization', 'Bearer ' + this.id_token);
    if (this.id_organisation !== '') {
      currentHeader = currentHeader.set('id_organisation', this.id_organisation);
    }
    this.currentHeader = currentHeader;
    return this.http.post(url, body, { headers: currentHeader });
  }


  /**
   * Post Request
   * @param url complete API url aanroep
   * @param body de body
   * @param headers HttpHeaders object
   */
  postArrayBufferResponse(url: string, body: any, headers ?: HttpHeaders): Observable < any > {
    let currentHeader = new HttpHeaders();
    currentHeader = currentHeader.set('Authorization', 'Bearer ' + this.id_token);
    if (this.id_organisation !== '') {
      currentHeader = currentHeader.set('id_organisation', this.id_organisation);
    }
    this.currentHeader = currentHeader;
    return this.http.post(url, body, { headers: currentHeader, responseType: 'arraybuffer' as 'json'  });
  }


}
